import { getConfig, getConfigValue } from './config';

const config = getConfig('client');

const policy = (nonce?: string) => ({
	'base-uri': ["'self'"],
	'connect-src': [
		"'self'",
		config['API_URL'].replace(/\/api$/, ''),
		config['SENTRY_REPORT_URI'],
		config['CDN'],
		'https://fonts.bunny.net',
		'https://*.ingest.sentry.io',
		'https://api.stripe.com',
		'https://formspree.io',
		config['ENVIRONMENT'] === 'production'
			? 'https://token-pay.nbcsportsnext.io/api/v1/paymentmethod/store/card'
			: 'https://rc2-token-pay.nbcsportsnext.io/api/v1/paymentmethod/store/card',
		config['ENVIRONMENT'] === 'development' ? 'wss://localhost.troon.com:*' : false,

		'https://www.googletagmanager.com', // GTM
		'https://www.google.com/ccm/', // GTM
		'https://google.com/pagead/', // GTM
		'https://google.com/ccm/', // GTM
		'https://connect.facebook.net', // GTM (Meta pixel)
		'https://www.facebook.com/tr/', // GTM (Meta)
		'https://px.ads.linkedin.com', // GTM (linkedin)
		'https://n.clarity.ms/collect', // GTM (bing)

		// Google Actions Center https://developers.google.com/actions-center/verticals/appointments/redirect/integration-steps/conversion-tracking
		getConfigValue('GOOGLE_ACTIONS_ENDPOINT'),
	],
	'default-src': ["'self'", "'unsafe-inline'"],
	'form-action': [
		"'self'",
		'https://www.facebook.com/tr/', // GTM (Meta)
	],
	'font-src': [
		"'self'",
		'https://fonts.bunny.net',

		// GTM Preview mode https://developers.google.com/tag-platform/security/guides/csp
		config['ENVIRONMENT'] !== 'production' && config['PIXEL_GTM'] ? 'https://fonts.gstatic.com' : false,
	],
	'frame-src': [
		"'self'",
		'https://js.stripe.com',
		'https://*.js.stripe.com',
		'https://hooks.stripe.com',
		'https://www.youtube-nocookie.com',
		'https://player.vimeo.com',
		'https://vcescert.visammg.com', // VISA + Troon Rewards
		'https://td.doubleclick.net', // GTM (gtag.js)
		'https://www.googletagmanager.com', // GTM
		'https://www.facebook.com/', // GTM (meta)
	],
	'img-src': [
		"'self'",
		'data:',
		'https://troon-cf.imglab-cdn.net',
		config['CDN'],
		`https://${config['MAP_HOST']}`,

		'https://www.googletagmanager.com', // GTM
		'https://www.google.com/pagead/', // GTM
		// GTM Preview mode https://developers.google.com/tag-platform/security/guides/csp
		...(config['ENVIRONMENT'] !== 'production' && config['PIXEL_GTM']
			? [
					'https://googletagmanager.com',
					'https://ssl.gstatic.com',
					'https://www.gstatic.com',
					'https://fonts.gstatic.com',
				]
			: []),
		'https://www.facebook.com', // gtm
		'https://px.ads.linkedin.com', // GTM (linkedin)'
		'https://c.bing.com', // GTM (bing)
		'https://bat.bing.com/action/', // GTM (bing)
		'https://c.clarity.ms', // GTM (bing)

		`https://images.ctfassets.net/${getConfigValue('CONTENTFUL_SPACEID')}/`,
	],
	'media-src': ['blob:', config['CDN']],
	'object-src': ["'none'"],
	'report-to': ['csp-endpoint'],
	'report-uri': [config['SENTRY_REPORT_URI']],
	'style-src-attr': ["'self'", "'unsafe-inline'", config['CDN']],
	'worker-src': ["'self'"],
	'style-src-elem': [
		"'self'",
		config['CDN'],
		// GTM Preview mode https://developers.google.com/tag-platform/security/guides/csp
		...(config['ENVIRONMENT'] !== 'production' && config['PIXEL_GTM']
			? ['https://www.googletagmanager.com', 'https://fonts.googleapis.com', "'unsafe-inline'"]
			: []),
		// In dev, the <style> element for Tailwind is replaced when it changes – and doesn't include the nonce
		config['ENVIRONMENT'] === 'development' || !nonce ? "'unsafe-inline'" : `'nonce-${nonce}'`,
	],
	'script-src': [
		"'self'",
		'https://*.js.stripe.com', // Stripe payments
		'https://js.stripe.com', // Stripe payments

		'https://www.googletagmanager.com', // GTM
		'https://connect.facebook.net', // GTM (meta pixel)
		// GTM Preview mode https://developers.google.com/tag-platform/security/guides/csp
		...(config['ENVIRONMENT'] !== 'production' && config['PIXEL_GTM']
			? ['https://googletagmanager.com', 'https://tagmanager.google.com']
			: []),

		config['CDN'],
		...(!nonce ? ["'unsafe-inline'"] : [`'nonce-${nonce}'`, "'strict-dynamic'"]),
	],
});

export function getContentSecurityPolicy(nonce?: string) {
	const csp: Array<string> = [];
	for (const [directive, values] of Object.entries(policy(nonce))) {
		csp.push(`${directive} ${values.filter(Boolean).join(' ')}`);
	}
	return csp.join('; ');
}
